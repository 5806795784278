.checkout {
  padding: 20px;
  background-color: rgb(24, 33, 82);
}
.checkout2 {
  padding: 20px;
  display: flex;
}
.checkout__div {
  margin-top: 100px;
}
.ck__greeting {
  font-weight: 600;
  margin-top: 20px;
}
.margin__subtotal {
  margin-top: 30px;
}
.checkout__ad {
  width: 100%;
  margin-top: 150px;
  margin-bottom: 10px;
}
.checkout__right {
  margin-bottom: 20px !important;
}
.checkout__title {
  margin-right: 10px;
  padding: 10px;
  font-weight: 700;
  border-bottom: 1px solid lightgray;
}
.checkout__title2 {
  margin-right: 10px;
  padding: 10px;
  font-weight: 700;
  border-bottom: 1px solid lightgray;
}
.dark__div {
  background-color: rgb(24, 33, 82) !important;
  z-index: 3 !important;
}
.white__bg {
  background-color: #ffffff;
}
.checkout__details__button {
  background: #f0c14b;
  border-radius: 2px;
  width: 100%;
  font-size: 1.6em;
  font-weight: 600;
  border: 1px solid;
  margin-top: 40px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}
.checkout__details__button > img {
  padding-top: 2px;
  padding-bottom: 1px;
  max-width: 30px;
  float: left;
}
#payment__form {
  margin-top: -20px;
}
