.subtotal {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 300px;
  height: 150px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: #f3f3f3;
  border: 1px solid #dddddd;
  border-radius: 3px;
  margin-bottom: 180px;
}
.checkout-btn {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 50px;
  padding-bottom: 33px;
  margin-top: 20px !important;
  padding-top: 5px;
  padding-left: 5px;
  padding-right: 5px;
}
.subtotal__gift {
  display: flex;
  align-items: center;
}

.subtotal__gift > input {
  margin-right: 5px;
}
.total__value {
  font-size: 1.7em;
}
.total__label {
  font-size: 20px;
  font-weight: 550;
}
.subtotal__label {
  font-size: 20px;
  font-weight: 500;
}
.subtotal > button {
  background: #f0c14b;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  margin-bottom: 10px;

  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}
.subtotal > button > img {
  max-width: 22px;
  float: left;
}
.payment__icon {
  max-width: 300px;
  margin-left: -21px;
}
.button__icon {
  padding-top: 2px;
}
.payment__icon__div {
  text-align: left;
  margin-top: -3px;
}
.payment__icon__div2 {
  text-align: left;
  margin-top: -19px;
}
