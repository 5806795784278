.checkoutProduct {
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
}

.checkoutProduct__info {
  padding-left: 20px;
}

.checkoutProduct__info > button {
  background: #f0c14b;
  border: 1px solid;
  margin-top: 10px;
  border-color: #a88734 #9c7e31 #846a29;
  color: #111;
}

.checkoutProduct__image {
  object-fit: contain;
  width: 120px;
  height: 120px;
  margin-bottom: 60px;
  margin-left: 20px;
}

.checkoutProduct__rating {
  display: flex;
}

.checkoutProduct__title {
  font-size: 17px;
  font-weight: 800;
}
.add__to__basket {
  bottom: 70px;
  position: absolute;
  margin-top: 10px;
  margin-left: 2px;
  margin-right: 22px;
  margin-bottom: 10px;
  right: 5px;
  color: #000000;
}
.basket-div {
  margin-top: 70px;
}

.rmv_basket {
  font-weight: 500;
}
