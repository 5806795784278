
.collapsible {
  background-color: #777;
  color: transparent;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.active, .collapsible:hover {
  background-color: #555;
}

.collapsible:after {
  content: '\002B';
  color: transparent;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.active:after {
  content: "\2212";
}

.content {
  padding: 0 18px;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: transparent;
}



.divider__img {
  vertical-align: bottom;
  margin-top: -135px;
  height: 100%;
  z-index: 3;
  width: 100%;

}
.divider__img2 {
  vertical-align: bottom;
  margin-top: -135px;
  height: 100%;
  z-index: 0;
  width: 100%;


}
.divider__img3 {
  vertical-align: bottom;
  margin-top: -135px;
  height: 100%;
  z-index: 2;


}
.divider__img__div {
  position: relative;
  
}

.divider__centertext {
    position: absolute;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%); 
    z-index: 2;


}
.divider__body { 
  margin-top: 15%;
    overflow: hidden;
    background: transparent;
    margin-bottom: -60%;
    margin-right: -40px

  }
  @media (max-width: 600px) {
    .divider__heading1 {
      font-size: 13vw !important;
      margin-top: 80% !important;
      margin-right: 10px !important;
      -webkit-text-stroke-width: 1px !important;
    }
  }


  @media (max-width: 790px) {
    .divider__heading {
      font-size: 11vw !important;
      margin-right: 0px !important;
      margin-top: 55% !important;
      -webkit-text-stroke-width: 1px !important;
    }
    
    .divider__heading2 {
      font-size: 10vw !important;
      margin-top: 43% !important;
      margin-right: 0px !important;
      -webkit-text-stroke-width: 1px !important;
    }
    .divider__heading3 {
      font-size: 11vw !important;
      padding-right: 10px !important;
      margin-top: 65% !important;
      -webkit-text-stroke-width: 1px !important;
    }
    .divider__heading4 {
      font-size: 11vw !important;
      padding-right: 10px !important;
      margin-top: 100% !important;
      -webkit-text-stroke-width: 1px !important;
    }
  }
.divider__heading {
    color: #FFFFFF;
    font-family: Tahoma, sans-serif;
    font-size: 8vw;
    font-weight: 900;
    margin-top: 70%;
    letter-spacing: -0.04em;
    -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(196, 14, 102);


  }
  .divider__heading1 {
    color: #FFFFFF;
    font-family: Tahoma, sans-serif;
    font-size: 10vw;
    font-weight: 900;
    margin-top: 65%;
    letter-spacing: -0.04em;    
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgb(196, 14, 102);

  }
  .divider__heading2 {
    color: #FFFFFF;
    font-family: Tahoma, sans-serif;
    font-size: 8vw;
    font-weight: 900;
    margin-top: 50%;
    letter-spacing: -0.04em;    
    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: rgb(196, 14, 102);

  }
  .divider__heading3 {
    color: #FFFFFF;
    font-family: Tahoma, sans-serif;
    font-size: 9vw;
    font-weight: 900;
    margin-top: 75%;
    letter-spacing: -0.04em;
    -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(196, 14, 102);


  }
  .divider__heading4 {
    color: #FFFFFF;
    font-family: Tahoma, sans-serif;
    font-size: 9vw;
    font-weight: 900;
    margin-top: 120%;
    letter-spacing: -0.04em;
    -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: rgb(196, 14, 102);


  }


.divider__body span {
    position: relative;
    display: block;
    width: 100%;
    height: 0;
    padding-top: 100%;
    overflow: hidden;
  }
.divider__body div {
    margin-top: -19%;
    height: 40%; 
    width: 5%;
    top: 22%;
    border-radius: 2px;
    position: absolute;
    margin-bottom: -20%;
  }
  .custom01
  .divider__body div:nth-of-type(0)  { animation: wave 17s   0.000s linear infinite; }
  .divider__body div:nth-of-type(0)  { animation: wave 0s   0.000s linear infinite; }
  .divider__body div:nth-of-type(1)  { animation: wave 17s   0.000s linear infinite; }
  .divider__body div:nth-of-type(2)  { animation: wave 17s -16.227s linear infinite; }
  .divider__body div:nth-of-type(3)  { animation: wave 17s -15.455s linear infinite; }
  .divider__body div:nth-of-type(4)  { animation: wave 17s -14.682s linear infinite; }
  .divider__body div:nth-of-type(5)  { animation: wave 17s -13.909s linear infinite; }
  .divider__body div:nth-of-type(6)  { animation: wave 17s -13.136s linear infinite; }
  .divider__body div:nth-of-type(7)  { animation: wave 17s -12.364s linear infinite; }
  .divider__body div:nth-of-type(8)  { animation: wave 17s -11.591s linear infinite; }
  .divider__body div:nth-of-type(9)  { animation: wave 17s -10.818s linear infinite; }
  .divider__body div:nth-of-type(10) { animation: wave 17s -10.045s linear infinite; }
  .divider__body div:nth-of-type(11) { animation: wave 17s  -9.273s linear infinite; }
  .divider__body div:nth-of-type(12) { animation: wave 17s  -8.500s linear infinite; }
  .divider__body div:nth-of-type(13) { animation: wave 17s  -7.727s linear infinite; }
  .divider__body div:nth-of-type(14) { animation: wave 17s  -6.955s linear infinite; }
  .divider__body div:nth-of-type(15) { animation: wave 17s  -6.182s linear infinite; }
  .divider__body div:nth-of-type(16) { animation: wave 17s  -5.409s linear infinite; }
  .divider__body div:nth-of-type(17) { animation: wave 17s  -4.636s linear infinite; }
  .divider__body div:nth-of-type(18) { animation: wave 17s  -3.864s linear infinite; }
  .divider__body div:nth-of-type(19) { animation: wave 17s  -3.091s linear infinite; }
  .divider__body div:nth-of-type(20) { animation: wave 17s  -2.318s linear infinite; }
  .divider__body div:nth-of-type(21) { animation: wave 17s  -1.545s linear infinite; }
  .divider__body div:nth-of-type(22) { animation: wave 17s  -0.773s linear infinite; }
  .custom01 {
      position: absolute;
        margin-inline-start: 0;
  }
  @keyframes wave {
    0%   { left:-5%; background: #51bcda; }
    5%   { background: rgb(185, 25, 159); }
    10%  { height:10%; margin-top: -5%; background: rgb(108, 36, 195); }
    15%  { background: #51bcda; }
    20%  { height:34%; margin-top:-17%; background:  rgb(108, 36, 195); }
    25%  { background: rgb(185, 25, 159); }
    30%  { height:10%; margin-top: -5%; background:  rgb(108, 36, 195); }
    35%  { background: #51bcda; }
    40%  { height:34%; margin-top:-17%; background: rgb(108, 36, 195);  }
    45%  { background: rgb(185, 25, 159); }
    50%  { height:10%; margin-top: -5%; background: rgb(108, 36, 195); }
    55%  { background: #51bcda; }
    60%  { height:34%; margin-top:-17%; background: rgb(108, 36, 195); }
    65%  { background: rgb(185, 25, 159); }
    70%  { height:10%; margin-top: -5%; background: rgb(108, 36, 195);  }
    75%  { background: #51bcda; }
    80%  { height:34%; margin-top:-17%; background: rgb(108, 36, 195); }
    85%  { background: rgb(185, 25, 159); }
    90%  { height:10%; margin-top: -5%; background: #51bcda; }
    95%  { background: #51bcda; }
    100% { height:34%; margin-top:-17%; left:100%; background: rgb(108, 36, 195); }
  }